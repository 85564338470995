import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';

interface ISWModalProps {
    isOpen: boolean;
    onModalToggle: () => void;
    className: string;
    modalTitle?: React.ReactNode;
    modalBody?: React.ReactNode;
    modalFooter?: React.ReactNode;
}

export default function SWModal(props: ISWModalProps) {
    const { isOpen, onModalToggle, className, modalTitle, modalBody, modalFooter } = props;

    return (
        <Modal
            isOpen={isOpen}
            zIndex={1050}
            applicationNode={'renderPage'}
            autoFocus={true}
            toggle={onModalToggle}
            className={`sw-modal ${className}`}
        >
            {modalTitle && <ModalHeader>{modalTitle}</ModalHeader>}
            {modalBody && <ModalBody className='sw-modal-body'>{modalBody}</ModalBody>}
            {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
        </Modal>
    );
}
