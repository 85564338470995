import React from 'react';
import Stepper, { Step } from 'react-material-stepper';
import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core-internal';
import { StepperAction, StepperContent, StepperContext } from 'react-material-stepper';
import Label from 'reactstrap/lib/Label';
import html2canvas from 'html2canvas';
import { ISalesLine, IOrderInformation, IOrderSummary } from '@msdyn365-commerce-modules/order-management';
import { IOrderDetailsResources, IReturnReasonOptionsData } from './../../definition-extensions/order-details.ext.props.autogenerated';
import { _getViewPort } from '../../../../utilities/get-viewport';
import { ISWGCustomerDataView } from '../../../../custom-proxy/RetailExtension/DataServiceEntities.g';
import 'react-material-stepper/dist/react-stepper.css';
import { PDFDocument, PDFFont, RGB, rgb, StandardFonts } from 'pdf-lib';

interface IReturnOrderSalesLine extends ISalesLine {
    returnQuantity?: number;
    returnPrice?: number;
    returnRemarks?: string;
    returnRemarksPlaceholder?: string;
    returnReason?: string;
}
interface ReturnOrderStepperProps {
    salesLines?: ISalesLine[];
    context: ICoreContext<IGeneric<IAny>>;
    orderInformation?: IOrderInformation;
    orderId?: string;
    orderSummary?: IOrderSummary;
    resources: IOrderDetailsResources;
    returnReasonOptions: IReturnReasonOptionsData[];
    logoUrl?: string;
    statement?: string;
    companyEmail?: string;
    companyData?: ISWGCustomerDataView;
    returnModalInfoText?: string;
    toggleModal: () => void;
}
interface StepProps {
    vertical?: boolean;
    resources: IOrderDetailsResources;
    context: ICoreContext<IGeneric<IAny>>;
    returnModalInfoText?: string;
    companyEmail?: string;
}

interface ProductDetailsStepProps extends StepProps {
    salesLines: IReturnOrderSalesLine[];
    returnReasonOptions: IReturnReasonOptionsData[];
}

interface SummaryStepProps extends StepProps {
    orderInformation: IOrderInformation;
    orderSummary: IOrderSummary;
    orderId?: string;
    logoUrl?: string;
    statement?: string;
    companyData?: ISWGCustomerDataView;
    toggleModal: () => void;
}

const ReturnOrderStepper: React.FunctionComponent<ReturnOrderStepperProps> = ({
    salesLines,
    orderInformation,
    orderSummary,
    context,
    orderId,
    resources,
    logoUrl,
    statement,
    companyEmail,
    companyData,
    returnModalInfoText,
    returnReasonOptions,
    toggleModal
}) => {
    const viewPort = _getViewPort(context);
    const isMobile = viewPort === 'sm' || viewPort === 'xs';
    return salesLines ? (
        <div>
            <Stepper vertical={isMobile}>
                <Step stepId='product-detail' title={resources.selectProductsStepLabel}>
                    <ProductDetailStep
                        returnReasonOptions={returnReasonOptions}
                        salesLines={salesLines}
                        context={context}
                        resources={resources}
                        returnModalInfoText={returnModalInfoText}
                        companyEmail={companyEmail}
                    />
                </Step>
                <Step stepId='comments-and-bankaccount' title={resources.additionalInfoStepLabel}>
                    <CommentsAndBankAccountStep
                        context={context}
                        resources={resources}
                        returnModalInfoText={returnModalInfoText}
                        companyEmail={companyEmail}
                    />
                </Step>
                {orderInformation && orderSummary && (
                    <Step stepId='summary' title={resources.summaryStepLabel}>
                        <SummaryStep
                            orderInformation={orderInformation}
                            orderSummary={orderSummary}
                            context={context}
                            resources={resources}
                            orderId={orderId}
                            logoUrl={logoUrl}
                            toggleModal={toggleModal}
                            statement={statement}
                            companyEmail={companyEmail}
                            companyData={companyData}
                            returnModalInfoText={returnModalInfoText}
                        />
                    </Step>
                )}
            </Stepper>
        </div>
    ) : null;
};

export const ProductDetailStep: React.FunctionComponent<ProductDetailsStepProps> = ({
    salesLines,
    vertical,
    context,
    resources,
    returnReasonOptions,
    returnModalInfoText,
    companyEmail
}) => {
    const [selectedProducts, setSelectedProducts] = React.useState<IReturnOrderSalesLine[]>([]);

    React.useEffect(() => {
        const previouslySelectedProducts: IReturnOrderSalesLine[] = getData('product-detail');
        if (previouslySelectedProducts && previouslySelectedProducts.length !== 0) {
            setSelectedProducts(selectedProducts => [...previouslySelectedProducts]);
        }
    }, []);

    const { resolve, reject, getData } = React.useContext(StepperContext);
    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedProducts.length === 0 || validateReturnReasonAndRemarks()) {
            reject(resources.selectProductsStepLabel);
        } else {
            resolve(selectedProducts);
        }
    };

    const validateReturnReasonAndRemarks = () => {
        const emptyDataProducts = selectedProducts.filter(product => {
            return !product.returnRemarks || product.returnRemarks === '' || !product.returnReason || product.returnReason === '';
        });
        return emptyDataProducts.length > 0;
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLSelectElement>, selectedLine: IReturnOrderSalesLine) => {
        selectedLine.returnQuantity = Number(event.target.value);
        selectedLine.returnPrice = (selectedLine.data.salesLine.Price || 0) * selectedLine.returnQuantity;
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, selectedLine: IReturnOrderSalesLine) => {
        const selectedProduct = selectedProducts.find(salesLine => {
            return salesLine.data.salesLine.ProductId === selectedLine.data.salesLine.ProductId;
        });

        if (selectedProduct) {
            const filteredProducts = selectedProducts.filter(product => {
                return selectedProduct.data.salesLine.ProductId !== product.data.salesLine.ProductId;
            });
            setSelectedProducts(filteredProducts);
        } else {
            setSelectedProducts(selectedProducts => [...selectedProducts, selectedLine]);
        }
    };

    const handleRemarksChange = (event: React.ChangeEvent<HTMLTextAreaElement>, selectedLine: IReturnOrderSalesLine) => {
        const { value } = event.target;
        selectedLine.returnRemarks = value;
    };
    const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>, selectedLine: IReturnOrderSalesLine) => {
        event.persist();
        const updatedSelectedProducts = [...selectedProducts];
        const selectedProductIndex = selectedProducts.findIndex(salesLine => {
            return salesLine.data.salesLine.ProductId === selectedLine.data.salesLine.ProductId;
        });
        if (selectedProductIndex < 0) {
            return;
        }
        const selectedProduct = selectedProducts[selectedProductIndex];
        const selectedOption = event.target.selectedOptions[0];
        const inputPlaceholder = selectedOption.getAttribute('data-option-placeholder');

        if (inputPlaceholder && selectedProduct) {
            selectedProduct.returnRemarksPlaceholder = inputPlaceholder;
        } else {
            selectedProduct.returnRemarksPlaceholder = '';
        }
        const { value } = event.target;
        selectedProduct.returnReason = value;
        updatedSelectedProducts[selectedProductIndex] = selectedProduct;
        setSelectedProducts(updatedSelectedProducts);
    };

    return (
        <StepperContent
            className='first-step'
            onSubmit={onSubmit}
            actions={
                <>
                    <p>
                        <i className='msi-InfoCircle' aria-hidden='true'></i>
                        {returnModalInfoText} <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                    </p>
                    <React.Fragment>
                        <div className='stepper__content__actions-btns'>
                            <StepperAction type='submit'>{resources.continueButtonLabel}</StepperAction>
                        </div>
                    </React.Fragment>
                </>
            }
        >
            <div className='checkbox-wrapper'>
                {salesLines &&
                    salesLines.map((salesLine, index) => {
                        const isSalesLineExists = selectedProducts.find(product => {
                            return product.data.salesLine.ProductId === salesLine.data.salesLine.ProductId;
                        });
                        return (
                            <div className='product-checkbox' key={index}>
                                <input
                                    name={salesLine.data.salesLine.ProductId?.toString()}
                                    type='checkbox'
                                    checked={isSalesLineExists ? true : false}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, salesLine)}
                                />
                                <div className='product-info'>
                                    <div className='product-specs-wrapper'>
                                        <div className='product-specs'>
                                            <Label check>{salesLine.data.product?.Description}</Label>
                                            <div>
                                                {resources.priceLabel}:{' '}
                                                {context.cultureFormatter.formatCurrency(salesLine.data.salesLine.TotalAmount!)}
                                            </div>
                                            <div>
                                                {resources.productDimensionTypeColor}:{' '}
                                                {salesLine.data.product?.Dimensions &&
                                                    salesLine.data.product?.Dimensions.length &&
                                                    salesLine.data.product?.Dimensions[0].DimensionValue?.Value}
                                            </div>
                                            <div>
                                                <label>{salesLine.data.salesLine.SalesOrderUnitOfMeasure?.toLowerCase()}: </label>
                                                <select
                                                    defaultValue={
                                                        salesLine.returnQuantity?.toString() ||
                                                        salesLine.data.salesLine.Quantity?.toString() ||
                                                        '1'
                                                    }
                                                    onChange={event => handleQuantityChange(event, salesLine)}
                                                >
                                                    {Array.from(Array(salesLine.data.salesLine.Quantity), (value, i) => {
                                                        return (
                                                            <option key={i} value={i + 1}>
                                                                {i + 1}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className='reason'>
                                            <label>{resources.returnReasonLabel}*</label>
                                            <select
                                                disabled={!isSalesLineExists}
                                                defaultValue={salesLine.returnReason}
                                                onChange={event => handleReasonChange(event, salesLine)}
                                            >
                                                {!salesLine.returnReason && <option value=''>{resources.selectReasonOptionLabel}</option>}
                                                {returnReasonOptions.map((reason, i) => {
                                                    return (
                                                        <option
                                                            value={`${reason.optionText}`}
                                                            data-option-placeholder={reason.inputPlaceholder}
                                                        >
                                                            {reason.optionText}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='remarks'>
                                            <label>{resources.remarksLabel}*</label>
                                            <textarea
                                                disabled={!isSalesLineExists}
                                                placeholder={salesLine.returnRemarksPlaceholder}
                                                defaultValue={salesLine.returnRemarks}
                                                onChange={event => handleRemarksChange(event, salesLine)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </StepperContent>
    );
};

export const CommentsAndBankAccountStep: React.FunctionComponent<StepProps> = ({
    vertical,
    resources,
    returnModalInfoText,
    companyEmail
}) => {
    const { resolve, reject, getData, goAt } = React.useContext(StepperContext);
    // const commentsField = React.useRef<HTMLDivElement>();
    const bankAccountField = React.useRef<HTMLDivElement>();
    // const SwiftCodeField = React.useRef<HTMLDivElement>();
    // const accountHolderField = React.useRef<HTMLDivElement>();
    // const commentsRef = React.useRef<HTMLTextAreaElement>();
    const bankAccountRef = React.useRef<HTMLInputElement>();
    const SwiftCodeRef = React.useRef<HTMLInputElement>();
    const accountHolderRef = React.useRef<HTMLInputElement>();
    const data = getData('comments-and-bankaccount') || {};

    const back = () => goAt('product-detail');
    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // const comments: string = commentsRef.current?.value!;
        const bankAccount: string = bankAccountRef.current?.value!;
        const accountHolder: string =  accountHolderRef.current?.value!;
        const swiftCode: string =  SwiftCodeRef.current?.value!;
        if (bankAccount.length === 0 || accountHolder.length===0) {
            reject(resources.additionalInfoStepLabel);
        } else {
            resolve({
                // comments,
                bankAccount,
                accountHolder,
                swiftCode
            });
        }
    };

    return (
        <StepperContent
            className='second-step'
            onSubmit={onSubmit}
            actions={
                <>
                    <p>
                        <i className='msi-InfoCircle' aria-hidden='true'></i>
                        {returnModalInfoText} <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                    </p>
                    <React.Fragment>
                        <div className='stepper__content__actions-btns'>
                            <StepperAction type='button' onClick={back}>
                                {resources.previousButtonLabel}
                            </StepperAction>
                            <StepperAction type='submit'>{resources.continueButtonLabel}</StepperAction>
                        </div>
                    </React.Fragment>
                </>
            }
        >
            <div className='form-group' ref={bankAccountField as React.RefObject<HTMLInputElement>}>
                <label>{resources.accountHolderNameLabel}*</label>
                <input
                    name='Account Holder'
                    ref={accountHolderRef as React.RefObject<HTMLInputElement>}
                    defaultValue={data.accountHolder}
                    type='text'
                />
                <label>{resources.accountNumberLabel}*</label>
                <input
                    name='Bank Account'
                    ref={bankAccountRef as React.RefObject<HTMLInputElement>}
                    defaultValue={data.bankAccount}
                    type='text'
                />
                <label>{resources.accountSwiftCodeLabel}</label>
                <input
                    name='Swift Code'
                    ref={SwiftCodeRef as React.RefObject<HTMLInputElement>}
                    defaultValue={data.swiftCode}
                    type='text'
                />
            </div>
            {/* <div className='form-group' ref={commentsField as React.RefObject<HTMLInputElement>}>
                <label>{resources.remarksLabel}</label>
                <textarea name='Comments' ref={commentsRef as React.RefObject<HTMLTextAreaElement>} defaultValue={data.comments} />
            </div> */}
        </StepperContent>
    );
};

export const SummaryStep: React.FunctionComponent<SummaryStepProps> = ({
    orderInformation,
    orderSummary,
    context,
    resources,
    orderId,
    logoUrl,
    statement,
    companyEmail,
    companyData,
    returnModalInfoText,
    toggleModal
}) => {
    const { resolve, getData, goAt } = React.useContext(StepperContext);
    const [isLoading, setIsLoading] = React.useState(false);

    const selectedProducts: IReturnOrderSalesLine[] = getData('product-detail');
    const commentsAndBankAccount = getData('comments-and-bankaccount');
    const back = () => goAt('comments-and-bankaccount');
    //can be used incase summary is needed
    //const summary = getData('summary');

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        resolve({ selectedProducts, commentsAndBankAccount, orderInformation, orderSummary });
    };
    const createOrderReturn = async () => {
        const pdfContainer = document.getElementById('pdf-container');
        if (pdfContainer) {
            setIsLoading(true);
            const height = pdfContainer.clientHeight;

            try {
                const canvas = await html2canvas(pdfContainer, {
                    imageTimeout: 0,
                    height: height,
                    scale: 3
                });
                const contentDataURL = canvas.toDataURL('image/jpeg');
                const pdfDoc = await PDFDocument.create();
                const page = pdfDoc.addPage();
                const { width, height: pageHeight } = page.getSize();
                const imgProps = await pdfDoc.embedJpg(contentDataURL);
                const pdfHeight = (imgProps.height * width) / imgProps.width;

                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
                const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                const embedLogoImage = async (logoUrl: string) => {
                    const response = await fetch(logoUrl);
                    const arrayBuffer = await response.arrayBuffer();
                    const contentType = response.headers.get('content-type');

                    if (contentType!.includes('image/jpeg')) {
                        return await pdfDoc.embedJpg(arrayBuffer);
                    } else if (contentType!.includes('image/png')) {
                        return await pdfDoc.embedPng(arrayBuffer);
                    } else {
                        throw new Error('Unsupported image format');
                    }
                };

                if (logoUrl) {
                    try {
                        const logoImage = await embedLogoImage(logoUrl);
                        page.drawImage(logoImage, {
                            x: 185,
                            y: pageHeight - 45,
                            width: 200,
                            height: 30
                        });
                    } catch (error) {
                        console.error('Error embedding logo image:', error);
                    }
                }

                const drawTextWithWrapping = (
                    text: string,
                    x: number,
                    y: number,
                    maxWidth: number,
                    fontSize: number,
                    font: PDFFont,
                    color: RGB
                ) => {
                    const words = text.split(' ');
                    let line = '';
                    let lineHeight = fontSize + 2; // Adjust line height as needed
                    let cursorY = y;
                    page.setFont(font);
                    page.setFontSize(fontSize);

                    words.forEach((word: string) => {
                        const testLine = line + word + ' ';
                        const testWidth = font.widthOfTextAtSize(testLine, fontSize);
                        if (testWidth > maxWidth && line !== '') {
                            page.drawText(line, { x, y: cursorY });
                            line = word + ' ';
                            cursorY -= lineHeight;
                        } else {
                            line = testLine;
                        }
                    });
                    page.drawText(line, { x, y: cursorY });
                };

                drawTextWithWrapping('Instructions', 12, pageHeight - 65, width - 24, 16, helveticaBoldFont, rgb(0.17, 0.15, 0.18));
                drawTextWithWrapping(statement || '', 12, pageHeight - 85, 517, 12, helveticaFont, rgb(0, 0, 0));
                drawTextWithWrapping(`Email: ${companyEmail}`, 12, pageHeight - 127, width - 24, 12, helveticaFont, rgb(0, 0, 0));

                page.drawImage(imgProps, {
                    x: 0,
                    y: pageHeight - 130 - pdfHeight,
                    width: width,
                    height: pdfHeight
                });

                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = `${orderId || ''}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setIsLoading(false);
                goAt('product-detail');
                toggleModal();
            } catch (error) {
                console.error('Error generating PDF:', error);
                setIsLoading(false);
            }
        }
    };

    return (
        <StepperContent
            className='third-step'
            onSubmit={onSubmit}
            actions={
                <>
                    <p>
                        <i className='msi-InfoCircle' aria-hidden='true'></i>
                        {returnModalInfoText} <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                    </p>
                    <React.Fragment>
                        <div className='stepper__content__actions-btns'>
                            <StepperAction type='button' onClick={back}>
                                {resources.previousButtonLabel}
                            </StepperAction>
                            <StepperAction
                                type='button'
                                className={`mdc-button--unelevated ${isLoading ? 'is-busy' : ''}`}
                                onClick={createOrderReturn}
                            >
                                {resources.completeButtonLabel}
                            </StepperAction>
                        </div>
                    </React.Fragment>
                </>
            }
        >
            {' '}
            <div className='pdf-wrapper'>
                <div id='pdf-container'>
                    {companyData && (
                        <div className='company-wrapper'>
                            <h2>{resources.companyInfoHeading}</h2>
                            <ul>
                                <li>
                                    <span>{resources.companyAccountNumberLabel}:</span>
                                    <span>{companyData.AdrNumber}</span>
                                </li>
                                <li>
                                    <span>{resources.contactPersonNameLabel}:</span>
                                    <span>{context.request.user.name}</span>
                                </li>
                                <li>
                                    <span>{resources.contactPersonEmailLabel}:</span>
                                    <span>{context.request.user.emailAddress}</span>
                                </li>
                                <li>
                                    <span>{resources.odCompanyNameLabel}:</span>
                                    <span>{companyData.CompName}</span>
                                </li>
                                <li>
                                    {/* <h3>{resources.companyAddressLabel}</h3> */}
                                    <span>{resources.odCompanyAddressLabel}:</span>
                                    <span>{`${companyData.Street1}, ${companyData.Town1}, ${companyData.SW_CountryIntex}`}</span>
                                </li>
                                <li>
                                    <span>{resources.odCompanyZipcodeLabel}:</span>
                                    <span>{companyData.Zip1}</span>
                                </li>
                            </ul>
                        </div>
                    )}
                    <h2>{resources.orderInfoHeading}</h2>
                    <h3 className='order-id'>{orderInformation.salesId}</h3>
                    <div className='products'>
                        {selectedProducts &&
                            selectedProducts.map((salesLine, index) => {
                                return (
                                    <div key={index} className='product'>
                                        <span className='product-name'>{salesLine.data.product?.Description}</span>
                                        <span>
                                            <b>{resources.productDimensionTypeColor}</b>:{' '}
                                            {salesLine.data.product?.Dimensions &&
                                                salesLine.data.product?.Dimensions.length &&
                                                salesLine.data.product?.Dimensions[0].DimensionValue?.Value}
                                        </span>

                                        <span>
                                            <b>{resources.priceLabel}</b>:{' '}
                                            {context.cultureFormatter.formatCurrency(
                                                salesLine.returnPrice ? salesLine.returnPrice : salesLine.data.salesLine.TotalAmount || 0
                                            )}
                                        </span>
                                        <span>
                                            <b>{salesLine.data.salesLine.SalesOrderUnitOfMeasure}</b>:{' '}
                                            {salesLine.returnQuantity ? salesLine.returnQuantity : salesLine.data.salesLine.Quantity}
                                        </span>
                                        <span>
                                            <b>{resources.returnReasonLabel}</b>: {salesLine.returnReason}
                                        </span>
                                        <span>
                                            <b>{resources.remarksLabel}</b>: {salesLine.returnRemarks}
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                    <div className='account-info'>
                        <h2>{resources.bankAccountInfoLabel}</h2>
                        <span className='account-value'>
                            <b>{resources.accountHolderNameLabel}</b>: {commentsAndBankAccount.accountHolder}
                        </span>
                        <span className='account-value'>
                            <b>{resources.accountNumberLabel}</b>: {commentsAndBankAccount.bankAccount}
                        </span>
                        <span className='account-value'>
                            <b>{resources.accountSwiftCodeLabel}</b>: {commentsAndBankAccount.swiftCode}
                        </span>
                    </div>
                    {/* <div className='comment-section'>
                        <h3>{resources.remarksLabel}</h3>
                        <p className='comments'>{commentsAndBankAccount.comments}</p>
                    </div> */}
                </div>
            </div>
        </StepperContent>
    );
};

export default ReturnOrderStepper;
